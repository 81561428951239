define("ember-bootstrap/components/bs-alert", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-alert"], function (_exports, _component, _bsAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let Alert = (_dec = (0, _component.classNameBindings)('showAlert:show'), _dec(_class = class Alert extends _bsAlert.default {}) || _class);
  _exports.default = Alert;
});