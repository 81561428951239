define("ember-bootstrap/components/bs-navbar", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-navbar", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsNavbar, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Navbar = (_dec = (0, _component.classNameBindings)('breakpointClass', 'backgroundClass'), _dec2 = Ember.computed('appliedType'), _dec3 = Ember.computed('toggleBreakpoint'), _dec4 = Ember.computed('backgroundColor'), _dec(_class = (_class2 = (_temp = class Navbar extends _bsNavbar.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "toggleBreakpoint", _descriptor, this);

      _initializerDefineProperty(this, "backgroundColor", _descriptor2, this);

      this._validPositions = ['fixed-top', 'fixed-bottom', 'sticky-top'];
      this._positionPrefix = '';
    }

    get type() {
      return this.get('appliedType') || 'light';
    }

    set type(value) {
      let newValue = !value || value === 'default' ? 'light' : value;
      this.set('appliedType', newValue);
      return newValue;
    }
    /**
     * Defines the responsive toggle breakpoint size. Options are the standard
     * two character Bootstrap size abbreviations. Used to set the `navbar-expand[-*]`
     * class. Set to `null` to disable collapsing.
     *
     * @property toggleBreakpoint
     * @type String
     * @default 'lg'
     * @public
     */


    get breakpointClass() {
      let toggleBreakpoint = this.get('toggleBreakpoint');

      if (Ember.isBlank(toggleBreakpoint)) {
        return 'navbar-expand';
      } else {
        return "navbar-expand-".concat(toggleBreakpoint);
      }
    }

    get backgroundClass() {
      let backgroundColor = this.get('backgroundColor');
      return "bg-".concat(backgroundColor);
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "type", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "type"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "toggleBreakpoint", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'lg';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "backgroundColor", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'light';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "breakpointClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "breakpointClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "backgroundClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "backgroundClass"), _class2.prototype)), _class2)) || _class);
  _exports.default = Navbar;
});