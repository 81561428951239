define("ember-bootstrap/components/base/bs-modal/header/close", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/header/close", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _close, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let ModalHeaderClose = (
  /**
  
   @class ModalHeaderClose
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.layout)(_close.default), _dec2 = (0, _component.tagName)('button'), _dec3 = (0, _component.classNames)('close'), _dec4 = (0, _component.attributeBindings)('type', 'aria-label'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = (_temp = class ModalHeaderClose extends Ember.Component {
    constructor(...args) {
      super(...args);
      this['aria-label'] = 'Close';

      _initializerDefineProperty(this, "type", _descriptor, this);
    }

    /**
     * @event onClick
     * @public
     */
    onClick() {}

    click() {
      this.get('onClick')();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  })), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = ModalHeaderClose;
});