define("ember-bootstrap/components/base/bs-alert", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-alert", "ember-bootstrap/utils/cp/type-class", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/cp/uses-transition", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _object, _bsAlert, _typeClass, _listenTo, _usesTransition, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let Alert = (
  /**
    Implements [Bootstrap alerts](http://getbootstrap.com/components/#alerts)
  
    ### Usage
  
    By default it is a user dismissible alert with a fade out animation, both of which can be disabled. Be sure to set the
    `type` property for proper styling.
  
    ```hbs
    <BsAlert @type="success">
      <strong>Well done!</strong> You successfully read this important alert message.
    </BsAlert>
    ```
  
    @class Alert
    @namespace Components
    @extends Ember.Component
    @public
  */
  _dec = (0, _component.layout)(_bsAlert.default), _dec2 = (0, _component.classNameBindings)('alert', 'fade', 'dismissible:alert-dismissible', 'typeClass'), _dec3 = (0, _listenTo.default)('visible'), _dec4 = Ember.computed.not('_visible'), _dec5 = Ember.computed.not('hidden'), _dec6 = Ember.computed.and('_visible', 'fade'), _dec7 = (0, _typeClass.default)('alert', 'type'), _dec8 = (0, _usesTransition.default)('fade'), _dec9 = (0, _object.observes)('_visible'), _dec(_class = _dec2(_class = (_class2 = (_temp = class Alert extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dismissible", _descriptor, this);

      _initializerDefineProperty(this, "hidden", _descriptor2, this);

      _initializerDefineProperty(this, "visible", _descriptor3, this);

      _initializerDefineProperty(this, "_visible", _descriptor4, this);

      _initializerDefineProperty(this, "notVisible", _descriptor5, this);

      _initializerDefineProperty(this, "fade", _descriptor6, this);

      _initializerDefineProperty(this, "alert", _descriptor7, this);

      _initializerDefineProperty(this, "showAlert", _descriptor8, this);

      _initializerDefineProperty(this, "fadeDuration", _descriptor9, this);

      _initializerDefineProperty(this, "type", _descriptor10, this);

      _initializerDefineProperty(this, "typeClass", _descriptor11, this);

      _initializerDefineProperty(this, "usesTransition", _descriptor12, this);
    }

    /**
     * The action to be sent after the alert has been dismissed (including the CSS transition).
     *
     * @event onDismissed
     * @public
     */
    onDismissed() {}
    /**
     * The action is called when the close button is clicked.
     *
     * You can return false to prevent closing the alert automatically, and do that in your action by
     * setting `visible` to false.
     *
     * @event onDismiss
     * @public
     */


    onDismiss() {}

    dismiss() {
      if (this.get('onDismiss')() !== false) {
        this.set('_visible', false);
      }
    }
    /**
     * Call to make the alert visible again after it has been hidden
     *
     * @method show
     * @private
     */


    show() {
      this.set('hidden', false);
    }
    /**
     * Call to hide the alert. If the `fade` property is true, this will fade out the alert before being finally
     * dismissed.
     *
     * @method hide
     * @private
     */


    hide() {
      if (this.get('usesTransition')) {
        Ember.run.later(this, function () {
          if (!this.get('isDestroyed')) {
            this.set('hidden', true);
            this.get('onDismissed')();
          }
        }, this.get('fadeDuration'));
      } else {
        this.set('hidden', true);
        this.get('onDismissed')();
      }
    }

    _observeIsVisible() {
      if (this.get('_visible')) {
        this.show();
      } else {
        this.hide();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "dismissible", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hidden", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return !this.get('_visible');
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "visible", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "_visible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "notVisible", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "alert", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "showAlert", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "fadeDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "usesTransition", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "dismiss", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "dismiss"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_observeIsVisible", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "_observeIsVisible"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Alert;
});