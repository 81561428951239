define("ember-bootstrap/components/base/bs-form/element/label", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-form/element/label", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _label, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let FormElementLabel = (
  /**
  
   @class FormElementLabel
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.layout)(_label.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed.equal('controlType', 'checkbox').readOnly(), _dec4 = Ember.computed.equal('formLayout', 'horizontal').readOnly(), _dec(_class = _dec2(_class = (_class2 = (_temp = class FormElementLabel extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "invisibleLabel", _descriptor, this);

      _initializerDefineProperty(this, "formLayout", _descriptor2, this);

      _initializerDefineProperty(this, "controlType", _descriptor3, this);

      _initializerDefineProperty(this, "isCheckbox", _descriptor4, this);

      _initializerDefineProperty(this, "isHorizontal", _descriptor5, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "invisibleLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "formLayout", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'vertical';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "controlType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'text';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isCheckbox", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isHorizontal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = FormElementLabel;
});