define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ah52fY+n",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"fluid\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[28,\"component\",[[23,0,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[23,0,[\"toggleNavbar\"]],[23,0,[\"_collapsed\"]]]]],[28,\"component\",[[23,0,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[23,0,[\"_collapsed\"]],[23,0,[\"onCollapsed\"]],[23,0,[\"onExpanded\"]]]]],[28,\"component\",[[23,0,[\"navComponent\"]]],[[\"linkToComponent\"],[[28,\"component\",[[23,0,[\"linkToComponent\"]]],[[\"onCollapse\"],[[23,0,[\"collapse\"]]]]]]]],[23,0,[\"collapse\"]],[23,0,[\"expand\"]],[23,0,[\"toggleNavbar\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[28,\"component\",[[23,0,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[23,0,[\"toggleNavbar\"]],[23,0,[\"_collapsed\"]]]]],[28,\"component\",[[23,0,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[23,0,[\"_collapsed\"]],[23,0,[\"onCollapsed\"]],[23,0,[\"onExpanded\"]]]]],[28,\"component\",[[23,0,[\"navComponent\"]]],[[\"linkToComponent\"],[[28,\"component\",[[23,0,[\"linkToComponent\"]]],[[\"onCollapse\"],[[23,0,[\"collapse\"]]]]]]]],[23,0,[\"collapse\"]],[23,0,[\"expand\"]],[23,0,[\"toggleNavbar\"]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});