define("ember-bootstrap/components/base/bs-form", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-form", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsForm, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let Form = (
  /**
    Render a form with the appropriate Bootstrap layout class (see `formLayout`).
    Allows setting a `model` that nested `Components.FormElement`s can access, and that can provide form validation (see below)
  
    You can use whatever markup you like within the form. The following shows Bootstrap 3 usage for the internal markup.
  
    ```handlebars
    <BsForm @onSubmit={{action "submit"}} as |form|>
      <form.group>
        <label class="control-label">First name</label>
        <input value={{this.firstname}} class="form-control" oninput={{action (mut this.firstname) value="target.value"}} type="text">
      </form.group>
    </BsForm>
    ```
  
    However to benefit from features such as automatic form markup, validations and validation markup, use `Components.FormElement`
    as nested components. See below for an example.
  
    ### Submitting the form
  
    When the form is submitted (e.g. by clicking a submit button), the event will be intercepted and the `onSubmit` action
    will be sent to the controller or parent component.
    In case the form supports validation (see "Form validation" below), the `onBefore` action is called (which allows you to
    do e.g. model data normalization), then the available validation rules are evaluated, and if those fail, the `onInvalid`
    action is sent instead of `onSubmit`.
  
    ### Use with Components.FormElement
  
    When using `Components.FormElement`s with their `property` set to property names of the form's validation enabled
    `model`, you gain some additional powerful features:
    * the appropriate Bootstrap markup for the given `formLayout` and the form element's `controlType` is automatically generated
    * markup for validation states and error messages is generated based on the model's validation (if available), when submitting the form
    with an invalid validation, or when focusing out of invalid inputs
  
    ```handlebars
    <BsForm @formLayout="horizontal" @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @controlType="email" @label="Email" @placeholder="Email" @property="email" />
      <form.element @controlType="password" @label="Password" @placeholder="Password" @property="password" />
      <form.element @controlType="checkbox" @label="Remember me" @property="rememberMe" />
      <BsButton @defaultText="Submit" @type="primary" @buttonType="submit" />
    </BsForm>
    ```
  
    See the [Components.FormElement](Components.FormElement.html) API docs for further information.
  
    ### Form validation
  
    All version of ember-bootstrap beginning from 0.7.0 do not come with built-in support for validation engines anymore.
    Instead support is added usually by additional Ember addons, for example:
  
    * [ember-bootstrap-validations](https://github.com/kaliber5/ember-bootstrap-validations): adds support for [ember-validations](https://github.com/DockYard/ember-validations)
    * [ember-bootstrap-cp-validations](https://github.com/offirgolan/ember-bootstrap-cp-validations): adds support for [ember-cp-validations](https://github.com/offirgolan/ember-cp-validations)
    * [ember-bootstrap-changeset-validations](https://github.com/kaliber5/ember-bootstrap-changeset-validations): adds support for [ember-changeset](https://github.com/poteto/ember-changeset)
  
    To add your own validation support, you have to:
  
    * extend this component, setting `hasValidator` to true if validations are available (by means of a computed property for example), and implementing the `validate` method
    * extend the [Components.FormElement](Components.FormElement.html) component and implement the `setupValidations` hook or simply override the `errors` property to add the validation error messages to be displayed
  
    When validation fails, the appropriate Bootstrap markup is added automatically, i.e. the error classes are applied and
    the validation messages are shown for each form element. In case the validation library supports it, also warning messages
    are shown. See the [Components.FormElement](Components.FormElement.html) documentation for further details.
  
    See the above mentioned addons for examples.
  
    ### Submission state
  
    A `isSubmitting` property is yielded, which is `true` after submit has been triggered and before the Promise returned
    by `onSubmit` is fulfilled. It could be used to disable form's submit button and showing a loading spinner for example:
  
    ```hbs
    <BsForm @onSubmit={{action "save"}} as |form|>
      <BsButton @buttonType="submit" @disabled={{form.isSubmitting}}>
        Save
        {{#if form.isSubmitting}} {{fa-icon "spinner"}} {{/if}}
      </BsButton>
    </BsForm>
    ```
  
    Additionaly `isSubmitted` and `isRejected` properties are yielded. `isSubmitted` is `true` if last submission was successful.
    `isRejected` is `true` if last submission was rejected due to validation errors or by an action bound to `onSubmit` event, returning a rejected promise.
    Both are reset as soon as any value of a form element changes. It could be used for visual feedback about last submission:
  
    ```hbs
    <BsForm @onSubmit={{action 'save}} as |form|>
      <BsButton @buttonType="submit" @type={{if form.isRejected "danger" "primary"}}>
        Save
      </BsButton>
    </BsForm>
    ```
  
    @class Form
    @namespace Components
    @extends Ember.Component
    @public
  */
  _dec = (0, _component.layout)(_bsForm.default), _dec2 = (0, _component.tagName)('form'), _dec3 = (0, _component.classNameBindings)('layoutClass'), _dec4 = (0, _component.attributeBindings)('_novalidate:novalidate'), _dec5 = Ember.computed.gt('pendingSubmissions', 0), _dec6 = Ember.computed('novalidate', 'hasValidator'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = (_temp = class Form extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.ariaRole = 'form';

      _initializerDefineProperty(this, "formLayout", _descriptor, this);

      _initializerDefineProperty(this, "horizontalLabelGridClass", _descriptor2, this);

      _initializerDefineProperty(this, "elementComponent", _descriptor3, this);

      _initializerDefineProperty(this, "groupComponent", _descriptor4, this);

      _initializerDefineProperty(this, "isSubmitting", _descriptor5, this);

      _initializerDefineProperty(this, "isSubmitted", _descriptor6, this);

      _initializerDefineProperty(this, "isRejected", _descriptor7, this);

      _initializerDefineProperty(this, "pendingSubmissions", _descriptor8, this);

      _initializerDefineProperty(this, "submitOnEnter", _descriptor9, this);

      _initializerDefineProperty(this, "preventConcurrency", _descriptor10, this);

      _initializerDefineProperty(this, "hideValidationsOnSubmit", _descriptor11, this);

      _initializerDefineProperty(this, "novalidate", _descriptor12, this);

      _initializerDefineProperty(this, "readonly", _descriptor13, this);

      _initializerDefineProperty(this, "disabled", _descriptor14, this);

      _initializerDefineProperty(this, "showAllValidations", _descriptor15, this);
    }

    get _novalidate() {
      return this.get('hasValidator') && this.get('novalidate') !== false || this.get('novalidate') === true ? '' : undefined;
    }
    /**
     * If set to true the `readonly` property of all yielded form elements will be set, making their form controls read-only.
     *
     * @property readonly
     * @type boolean
     * @default false
     * @public
     */


    /**
     * Validate hook which will return a promise that will either resolve if the model is valid
     * or reject if it's not. This should be overridden to add validation support.
     *
     * @method validate
     * @param {Object} model
     * @return {Promise}
     * @public
     */
    validate(model) {} // eslint-disable-line no-unused-vars

    /**
     * @property showAllValidations
     * @type boolean
     * @default undefined
     * @private
     */


    /**
     * Action is called before the form is validated (if possible) and submitted.
     *
     * @event onBefore
     * @param { Object } model  The form's `model`
     * @public
     */
    onBefore(model) {} // eslint-disable-line no-unused-vars

    /**
     * Action is called when submit has been triggered and the model has passed all validations (if present).
     *
     * @event onSubmit
     * @param { Object } model  The form's `model`
     * @param { Object } result The returned result from the validate method, if validation is available
     * @public
     */


    onSubmit(model, result) {} // eslint-disable-line no-unused-vars

    /**
     * Action is called when validation of the model has failed.
     *
     * @event onInvalid
     * @param { Object } model  The form's `model`
     * @param { Object } error
     * @public
     */


    onInvalid(model, error) {} // eslint-disable-line no-unused-vars

    /**
     * Submit handler that will send the default action ("action") to the controller when submitting the form.
     *
     * If there is a supplied `model` that supports validation (`hasValidator`) the model will be validated before, and
     * only if validation is successful the default action will be sent. Otherwise an "invalid" action will be sent, and
     * all the `showValidation` property of all child `Components.FormElement`s will be set to true, so error state and
     * messages will be shown automatically.
     *
     * @method submit
     * @private
     */


    submitHandler(e, throwValidationErrors = true) {
      if (e) {
        e.preventDefault();
      }

      if (this.get('preventConcurrency') && this.get('isSubmitting')) {
        return Ember.RSVP.resolve();
      }

      let model = this.get('model');
      this.incrementProperty('pendingSubmissions');
      this.get('onBefore')(model);
      return Ember.RSVP.resolve().then(() => {
        return this.get('hasValidator') ? this.validate(model) : null;
      }).then(record => {
        if (this.get('hideValidationsOnSubmit') === true) {
          this.set('showAllValidations', false);
        }

        return Ember.RSVP.resolve().then(() => {
          return this.get('onSubmit')(model, record);
        }).then(() => {
          if (this.get('isDestroyed')) {
            return;
          }

          this.set('isSubmitted', true);
        }).catch(error => {
          if (this.get('isDestroyed')) {
            return;
          }

          this.set('isRejected', true);
          throw error;
        }).finally(() => {
          if (this.get('isDestroyed')) {
            return;
          }

          this.decrementProperty('pendingSubmissions'); // reset forced hiding of validations

          if (this.get('showAllValidations') === false) {
            Ember.run.schedule('afterRender', () => this.set('showAllValidations', undefined));
          }
        });
      }, error => {
        return Ember.RSVP.resolve().then(() => {
          return this.get('onInvalid')(model, error);
        }).finally(() => {
          if (this.get('isDestroyed')) {
            return;
          }

          this.setProperties({
            showAllValidations: true,
            isRejected: true,
            pendingSubmissions: this.get('pendingSubmissions') - 1
          });

          if (throwValidationErrors) {
            throw error;
          }
        });
      });
    }

    submit(event) {
      this.submitHandler(event, false);
    }

    keyPress(e) {
      let code = e.keyCode || e.which;

      if (code === 13 && this.get('submitOnEnter')) {
        this.triggerSubmit();
      }
    }

    triggerSubmit() {
      let event = document.createEvent('Event');
      event.initEvent('submit', true, true);
      this.get('element').dispatchEvent(event);
    }

    init() {
      super.init(...arguments);
      let formLayout = this.get('formLayout');
      (true && !(['vertical', 'horizontal', 'inline'].indexOf(formLayout) >= 0) && Ember.assert("Invalid formLayout property given: ".concat(formLayout), ['vertical', 'horizontal', 'inline'].indexOf(formLayout) >= 0));
    }

    elementChanged(value, model, property) {
      (true && !(Ember.isPresent(model) && Ember.isPresent(property)) && Ember.assert('You cannot use the form element\'s default onChange action for form elements if not using a model or setting the value directly on a form element. You must add your own onChange action to the form element in this case!', Ember.isPresent(model) && Ember.isPresent(property)));

      if (typeof model.set === 'function') {
        model.set(property, value);
      } else {
        Ember.set(model, property, value);
      }
    }

    resetSubmissionState() {
      this.set('isSubmitted', false);
      this.set('isRejected', false);
    }

    doSubmit() {
      return this.submitHandler();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "formLayout", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'vertical';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "horizontalLabelGridClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'col-md-4';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "elementComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-form/element';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "groupComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-form/group';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isSubmitting", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isSubmitted", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "isRejected", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "pendingSubmissions", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "submitOnEnter", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "preventConcurrency", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "hideValidationsOnSubmit", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "novalidate", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "_novalidate", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_novalidate"), _class2.prototype), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "readonly", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "showAllValidations", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "elementChanged", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "elementChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetSubmissionState", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetSubmissionState"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doSubmit", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "doSubmit"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = Form;
});