define("ember-bootstrap/components/base/bs-modal-simple", ["exports", "@ember-decorators/component", "ember-bootstrap/components/bs-modal", "ember-bootstrap/templates/components/bs-modal-simple", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsModal, _bsModalSimple, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let ModalSimple = (
  /**
    Component for creating [Bootstrap modals](http://getbootstrap.com/javascript/#modals) with a some common default markup
    including a header, footer and body. Creating a simple modal is easy:
  
    ```hbs
    {{#bs-modal-simple title="Simple Dialog"}}
      Hello world!
    {{/bs-modal-simple}}
    ```
  
    This will automatically create the appropriate markup, with a modal header containing the title, and a footer containing
    a default "Ok" button, that will close the modal automatically (unless you return false from `onHide`).
  
    A modal created this way will be visible at once. You can use the `{{#if ...}}` helper to hide all modal elements from
    the DOM until needed. Or you can bind the `open` property to trigger showing and hiding the modal:
  
    ```hbs
    <BsModalSimple @open={{this.openModal}} @title="Simple Dialog">
      Hello world!
    </BsModalSimple>
    ```
  
    ### Custom Markup
  
    To customize the markup within the modal you can use the [bs-modal](Components.Modal.html) component.
  
    ### Modals with forms
  
    There is a special case when you have a form inside your modals body: you probably do not want to have a submit button
    within your form but instead in your modal footer. Hover pressing the submit button outside of your form would not
    trigger the form data to be submitted. In the example below this would not trigger the submit action of the form, an
    thus bypass the form validation feature of the form component.
  
    ```hbs
    <BsModalSimple @title="Form Example" @closeTitle="Cancel" @submitTitle="Ok">
      <BsForm @model={{this}} @onSubmit={{action "submit"}} as |Form|>
        <Form.element @controlType="text" @label="first name" @property="firstname" />
        <Form.element @controlType="text" @label="last name" @property="lastname" />
      </BsForm>
    </BsModalSimple>
    ```
  
    The modal component supports this common case by triggering the submit event programmatically on the body's form if
    present whenever the footer's submit button is pressed, so the example above will work as expected.
  
    ### Auto-focus
  
    In order to allow key handling to function, the modal's root element is given focus once the modal is shown. If your
    modal contains an element such as a text input and you would like it to be given focus rather than the modal element,
    then give it the HTML5 autofocus attribute:
  
    ```hbs
    <BsModalSimple @title="Form Example" @closeTitle="Cancel" @submitTitle="Ok">
      <BsForm @model={{this}} @onSubmit={{action "submit"}} as |Form|>
        <Form.element @controlType="text" @label="first name" @property="firstname" @autofocus={{true}} />
        <Form.element @controlType="text" @label="last name" @property="lastname" />
      </BsForm>
    </BsModalSimple>
    ```
  
    ### Modals inside wormhole
  
    Modals make use of the [ember-wormhole](https://github.com/yapplabs/ember-wormhole) addon, which will be installed
    automatically alongside ember-bootstrap. This is used to allow the modal to be placed in deeply nested
    components/templates where it belongs to logically, but to have the actual DOM elements within a special container
    element, which is a child of ember's root element. This will make sure that modals always overlay the whole app, and
    are not effected by parent elements with `overflow: hidden` for example.
  
    If you want the modal to render in place, rather than being wormholed, you can set renderInPlace=true.
  
    @class ModalSimple
    @namespace Components
    @extends Components.Modal
    @public
  */
  _dec = (0, _component.layout)(_bsModalSimple.default), _dec(_class = (_class2 = (_temp = class ModalSimple extends _bsModal.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "closeButton", _descriptor, this);

      _initializerDefineProperty(this, "closeTitle", _descriptor2, this);

      _initializerDefineProperty(this, "submitButtonType", _descriptor3, this);
    }
    /**
     * The title of the submit button (primary button). Will be ignored (i.e. no button) if set to null.
     *
     * @property submitTitle
     * @type string
     * @default null
     * @public
     */


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "closeButton", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "closeTitle", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Ok';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "submitButtonType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'primary';
    }
  })), _class2)) || _class);
  _exports.default = ModalSimple;
});