define("ember-bootstrap/components/bs-modal/dialog", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-modal/dialog"], function (_exports, _component, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  let ModalDialog = (_dec = (0, _component.classNameBindings)('showModal:show', 'inDom:d-block'), _dec(_class = (_temp = class ModalDialog extends _dialog.default {
    constructor(...args) {
      super(...args);
      this.centered = false;
    }

  }, _temp)) || _class);
  _exports.default = ModalDialog;
});