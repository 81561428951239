define("ember-bootstrap/components/base/bs-accordion/item", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-accordion/item", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _item, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let AccordionItem = (
  /**
   A collapsible/expandable item within an accordion
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItem
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _component.layout)(_item.default), _dec2 = (0, _component.classNameBindings)('disabled', 'typeClass'), _dec3 = Ember.computed.oneWay('elementId'), _dec4 = Ember.computed('value', 'selected').readOnly(), _dec5 = Ember.computed.not('collapsed'), _dec(_class = _dec2(_class = (_class2 = (_temp = class AccordionItem extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "title", _descriptor, this);

      _initializerDefineProperty(this, "value", _descriptor2, this);

      _initializerDefineProperty(this, "selected", _descriptor3, this);

      _initializerDefineProperty(this, "titleComponent", _descriptor4, this);

      _initializerDefineProperty(this, "bodyComponent", _descriptor5, this);

      _initializerDefineProperty(this, "active", _descriptor6, this);

      _initializerDefineProperty(this, "disabled", _descriptor7, this);

      _initializerDefineProperty(this, "type", _descriptor8, this);
    }

    /**
     * @property collapsed
     * @type boolean
     * @readonly
     * @private
     */
    get collapsed() {
      return this.get('value') !== this.get('selected');
    }
    /**
     * @property active
     * @type boolean
     * @readonly
     * @private
     */


    /**
     * Reference to the parent `Components.Accordion` class.
     *
     * @property accordion
     * @private
     */

    /**
     * @event onClick
     * @public
     */
    onClick() {}

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "value", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "selected", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "titleComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-accordion/item/title';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "bodyComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-accordion/item/body';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "collapsed", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "collapsed"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  })), _class2)) || _class) || _class);
  _exports.default = AccordionItem;
});