define("ember-bootstrap/components/base/bs-modal", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-modal", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/dom", "ember-bootstrap/utils/cp/uses-transition", "ember-bootstrap/utils/is-fastboot", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _object, _bsModal, _listenTo, _transitionEnd, _dom, _usesTransition, _isFastboot, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let Modal = (
  /**
    Component for creating [Bootstrap modals](http://getbootstrap.com/javascript/#modals) with custom markup.
  
    ### Usage
  
    ```hbs
    <BsModal @onSubmit={{action "submit"}} as |Modal|>
      <Modal.header>
        <h4 class="modal-title"><i class="glyphicon glyphicon-alert"></i> Alert</h4>
      </Modal.header>
      <Modal.body>
        Are you absolutely sure you want to do that???
      </Modal.body>
      <Modal.footer as |footer|>
        <BsButton @onClick={{action Modal.close}} @type="danger">Oh no, forget it!</BsButton>
        <BsButton @onClick={{action Modal.submit}} @type="success">Yeah!</BsButton>
      </Modal.footer>
    </BsModal>
    ```
  
    The component yields references to the following contextual components, that you can use to further customize the output:
  
    * [modal.body](Components.ModalBody.html)
    * [modal.header](Components.ModalHeader.html)
    * [modal.footer](Components.ModalFooter.html)
  
    Furthermore references to the following actions are yielded:
  
    * `close`: triggers the `onHide` action and closes the modal
    * `submit`: triggers the `onSubmit` action (or the submit event on a form if present in the body element)
  
    ### Further reading
  
    See the documentation of the [bs-modal-simple](Components.ModalSimple.html) component for further examples.
  
    @class Modal
    @namespace Components
    @extends Ember.Component
    @public
  */
  _dec = (0, _component.layout)(_bsModal.default), _dec2 = (0, _component.tagName)(''), _dec3 = (0, _listenTo.default)('open'), _dec4 = Ember.computed.not('fade'), _dec5 = Ember.computed('renderInPlace', 'destinationElement'), _dec6 = (0, _usesTransition.default)('fade'), _dec7 = (0, _object.observes)('isOpen'), _dec(_class = _dec2(_class = (_class2 = (_temp = class Modal extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "open", _descriptor, this);

      _initializerDefineProperty(this, "isOpen", _descriptor2, this);

      this._isOpen = false;

      _initializerDefineProperty(this, "fade", _descriptor3, this);

      _initializerDefineProperty(this, "notFade", _descriptor4, this);

      _initializerDefineProperty(this, "showModal", _descriptor5, this);

      _initializerDefineProperty(this, "inDom", _descriptor6, this);

      _initializerDefineProperty(this, "paddingLeft", _descriptor7, this);

      _initializerDefineProperty(this, "paddingRight", _descriptor8, this);

      _initializerDefineProperty(this, "backdrop", _descriptor9, this);

      _initializerDefineProperty(this, "showBackdrop", _descriptor10, this);

      _initializerDefineProperty(this, "keyboard", _descriptor11, this);

      _initializerDefineProperty(this, "position", _descriptor12, this);

      _initializerDefineProperty(this, "dialogComponent", _descriptor13, this);

      _initializerDefineProperty(this, "headerComponent", _descriptor14, this);

      _initializerDefineProperty(this, "bodyComponent", _descriptor15, this);

      _initializerDefineProperty(this, "footerComponent", _descriptor16, this);

      _initializerDefineProperty(this, "size", _descriptor17, this);

      _initializerDefineProperty(this, "backdropClose", _descriptor18, this);

      _initializerDefineProperty(this, "renderInPlace", _descriptor19, this);

      _initializerDefineProperty(this, "transitionDuration", _descriptor20, this);

      _initializerDefineProperty(this, "backdropTransitionDuration", _descriptor21, this);

      _initializerDefineProperty(this, "usesTransition", _descriptor22, this);
    }

    /**
     * The id of the `.modal` element.
     *
     * @property modalId
     * @type string
     * @readonly
     * @private
     */
    get modalId() {
      return "".concat(Ember.guidFor(this), "-modal");
    }
    /**
     * The id of the backdrop element.
     *
     * @property backdropId
     * @type string
     * @readonly
     * @private
     */


    get backdropId() {
      return "".concat(Ember.guidFor(this), "-backdrop");
    }
    /**
     * Property for size styling, set to null (default), 'lg' or 'sm'
     *
     * Also see the [Bootstrap docs](http://getbootstrap.com/javascript/#modals-sizes)
     *
     * @property size
     * @type String
     * @public
     */


    /**
     * @property _renderInPlace
     * @type boolean
     * @private
     */
    get _renderInPlace() {
      return this.get('renderInPlace') || !this.destinationElement;
    }
    /**
     * The duration of the fade transition
     *
     * @property transitionDuration
     * @type number
     * @default 300
     * @public
     */


    /**
     * The DOM element of the `.modal` element.
     *
     * @property modalElement
     * @type object
     * @readonly
     * @private
     */
    get modalElement() {
      return document.getElementById(this.get('modalId'));
    }
    /**
     * The DOM element of the backdrop element.
     *
     * @property backdropElement
     * @type object
     * @readonly
     * @private
     */


    get backdropElement() {
      return document.getElementById(this.get('backdropId'));
    }
    /**
     * The action to be sent when the modal footer's submit button (if present) is pressed.
     * Note that if your modal body contains a form (e.g. [Components.Form](Components.Form.html)) this action will
     * not be triggered. Instead a submit event will be triggered on the form itself. See the class description for an
     * example.
     *
     * @property onSubmit
     * @type function
     * @public
     */


    onSubmit() {}
    /**
     * The action to be sent when the modal is closing.
     * This will be triggered by pressing the modal header's close button (x button) or the modal footer's close button.
     * Note that this will happen before the modal is hidden from the DOM, as the fade transitions will still need some
     * time to finish. Use the `onHidden` if you need the modal to be hidden when the action triggers.
     *
     * You can return false to prevent closing the modal automatically, and do that in your action by
     * setting `open` to false.
     *
     * @property onHide
     * @type function
     * @public
     */


    onHide() {}
    /**
     * The action to be sent after the modal has been completely hidden (including the CSS transition).
     *
     * @property onHidden
     * @type function
     * @default null
     * @public
     */


    onHidden() {}
    /**
     * The action to be sent when the modal is opening.
     * This will be triggered immediately after the modal is shown (so it's safe to access the DOM for
     * size calculations and the like). This means that if fade=true, it will be shown in between the
     * backdrop animation and the fade animation.
     *
     * @property onShow
     * @type function
     * @default null
     * @public
     */


    onShow() {}
    /**
     * The action to be sent after the modal has been completely shown (including the CSS transition).
     *
     * @property onShown
     * @type function
     * @public
     */


    onShown() {}

    close() {
      if (this.get('onHide')() !== false) {
        this.set('isOpen', false);
      }
    }

    doSubmit() {
      // replace modalId by :scope selector if supported by all target browsers
      let modalId = this.get('modalId');
      let forms = this.get('modalElement').querySelectorAll("#".concat(modalId, " .modal-body form"));

      if (forms.length > 0) {
        // trigger submit event on body forms
        let event = document.createEvent('Events');
        event.initEvent('submit', true, true);
        Array.prototype.slice.call(forms).forEach(form => form.dispatchEvent(event));
      } else {
        // if we have no form, we send a submit action
        this.get('onSubmit')();
      }
    }
    /**
     * Give the modal (or its autofocus element) focus
     *
     * @method takeFocus
     * @private
     */


    takeFocus() {
      let modalEl = this.get('modalElement');
      let focusElement = modalEl && modalEl.querySelector('[autofocus]');

      if (!focusElement) {
        focusElement = modalEl;
      }

      if (focusElement) {
        focusElement.focus();
      }
    }
    /**
     * Show the modal
     *
     * @method show
     * @private
     */


    show() {
      if (this._isOpen) {
        return;
      }

      this._isOpen = true;
      document.body.classList.add('modal-open');
      this.resize();

      let callback = () => {
        if (this.get('isDestroyed')) {
          return;
        }

        this.checkScrollbar();
        this.setScrollbar();
        Ember.run.schedule('afterRender', () => {
          let modalEl = this.get('modalElement');

          if (!modalEl) {
            return;
          }

          modalEl.scrollTop = 0;
          this.handleUpdate();
          this.set('showModal', true);
          this.get('onShow')();

          if (this.get('usesTransition')) {
            (0, _transitionEnd.default)(this.get('modalElement'), this.get('transitionDuration')).then(() => {
              this.takeFocus();
              this.get('onShown')();
            });
          } else {
            this.takeFocus();
            this.get('onShown')();
          }
        });
      };

      if (this.get('inDom') !== true) {
        this.set('inDom', true);
      }

      this.handleBackdrop(callback);
    }
    /**
     * Hide the modal
     *
     * @method hide
     * @private
     */


    hide() {
      if (!this._isOpen) {
        return;
      }

      this._isOpen = false;
      this.resize();
      this.set('showModal', false);

      if (this.get('usesTransition')) {
        (0, _transitionEnd.default)(this.get('modalElement'), this.get('transitionDuration')).then(() => this.hideModal());
      } else {
        this.hideModal();
      }
    }
    /**
     * Clean up after modal is hidden and call onHidden
     *
     * @method hideModal
     * @private
     */


    hideModal() {
      if (this.get('isDestroyed')) {
        return;
      }

      this.handleBackdrop(() => {
        document.body.classList.remove('modal-open');
        this.resetAdjustments();
        this.resetScrollbar();
        this.set('inDom', false);
        this.get('onHidden')();
      });
    }
    /**
     * SHow/hide the backdrop
     *
     * @method handleBackdrop
     * @param callback
     * @private
     */


    handleBackdrop(callback) {
      let doAnimate = this.get('usesTransition');

      if (this.get('isOpen') && this.get('backdrop')) {
        this.set('showBackdrop', true);

        if (!callback) {
          return;
        }

        Ember.run.schedule('afterRender', this, function () {
          let backdrop = this.get('backdropElement');
          (true && !(backdrop) && Ember.assert('Backdrop element should be in DOM', backdrop));

          if (doAnimate) {
            (0, _transitionEnd.default)(backdrop, this.get('backdropTransitionDuration')).then(callback);
          } else {
            callback();
          }
        });
      } else if (!this.get('isOpen') && this.get('backdrop')) {
        let backdrop = this.get('backdropElement');
        (true && !(backdrop) && Ember.assert('Backdrop element should be in DOM', backdrop));

        let callbackRemove = () => {
          if (this.get('isDestroyed')) {
            return;
          }

          this.set('showBackdrop', false);

          if (callback) {
            callback.call(this);
          }
        };

        if (doAnimate) {
          (0, _transitionEnd.default)(backdrop, this.get('backdropTransitionDuration')).then(callbackRemove);
        } else {
          callbackRemove();
        }
      } else if (callback) {
        Ember.run.next(this, callback);
      }
    }
    /**
     * Attach/Detach resize event listeners
     *
     * @method resize
     * @private
     */


    resize() {
      if (this.get('isOpen')) {
        this._handleUpdate = Ember.run.bind(this, this.handleUpdate);
        window.addEventListener('resize', this._handleUpdate, false);
      } else {
        window.removeEventListener('resize', this._handleUpdate, false);
      }
    }
    /**
     * @method handleUpdate
     * @private
     */


    handleUpdate() {
      this.adjustDialog();
    }
    /**
     * @method adjustDialog
     * @private
     */


    adjustDialog() {
      let modalIsOverflowing = this.get('modalElement').scrollHeight > document.documentElement.clientHeight;
      this.setProperties({
        paddingLeft: !this.bodyIsOverflowing && modalIsOverflowing ? this.get('scrollbarWidth') : null,
        paddingRight: this.bodyIsOverflowing && !modalIsOverflowing ? this.get('scrollbarWidth') : null
      });
    }
    /**
     * @method resetAdjustments
     * @private
     */


    resetAdjustments() {
      this.setProperties({
        paddingLeft: null,
        paddingRight: null
      });
    }
    /**
     * @method checkScrollbar
     * @private
     */


    checkScrollbar() {
      let fullWindowWidth = window.innerWidth;

      if (!fullWindowWidth) {
        // workaround for missing window.innerWidth in IE8
        let documentElementRect = document.documentElement.getBoundingClientRect();
        fullWindowWidth = documentElementRect.right - Math.abs(documentElementRect.left);
      }

      this.bodyIsOverflowing = document.body.clientWidth < fullWindowWidth;
    }
    /**
     * @method setScrollbar
     * @private
     */


    setScrollbar() {
      let bodyPad = parseInt(document.body.style.paddingRight || 0, 10);
      this._originalBodyPad = document.body.style.paddingRight || '';

      if (this.bodyIsOverflowing) {
        document.body.style.paddingRight = bodyPad + this.get('scrollbarWidth');
      }
    }
    /**
     * @method resetScrollbar
     * @private
     */


    resetScrollbar() {
      document.body.style.paddingRight = this._originalBodyPad;
    }
    /**
     * @property scrollbarWidth
     * @type number
     * @readonly
     * @private
     */


    get scrollbarWidth() {
      let scrollDiv = document.createElement('div');
      scrollDiv.className = 'modal-scrollbar-measure';
      let modalEl = this.get('modalElement');
      modalEl.parentNode.insertBefore(scrollDiv, modalEl.nextSibling);
      let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      scrollDiv.parentNode.removeChild(scrollDiv);
      return scrollbarWidth;
    }

    didInsertElement() {
      super.didInsertElement(...arguments);

      if (this.get('isOpen')) {
        this.show();
      }
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      window.removeEventListener('resize', this._handleUpdate, false);
      document.body.classList.remove('modal-open');
      this.resetScrollbar();
    }

    _observeOpen() {
      if (this.get('isOpen')) {
        this.show();
      } else {
        this.hide();
      }
    }

    init() {
      super.init(...arguments);
      let {
        isOpen,
        backdrop,
        fade
      } = this.getProperties('isOpen', 'backdrop', 'fade');
      let isFB = (0, _isFastboot.default)(this);

      if (fade === undefined) {
        fade = !isFB;
      }

      this.setProperties({
        showModal: isOpen && (!fade || isFB),
        showBackdrop: isOpen && backdrop,
        inDom: isOpen,
        fade,
        destinationElement: (0, _dom.getDestinationElement)(this)
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "open", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isOpen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "notFade", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "showModal", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "inDom", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "paddingLeft", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "paddingRight", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "backdrop", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "showBackdrop", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "keyboard", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "position", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'top';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "dialogComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/dialog';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "headerComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/header';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "bodyComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/body';
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "footerComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/footer';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "modalId", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "modalId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "backdropId", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "backdropId"), _class2.prototype), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "backdropClose", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "renderInPlace", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "_renderInPlace", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_renderInPlace"), _class2.prototype), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "transitionDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 300;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "backdropTransitionDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class2.prototype, "usesTransition", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "close", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doSubmit", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "doSubmit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "scrollbarWidth", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "scrollbarWidth"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_observeOpen", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "_observeOpen"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Modal;
});