define("ember-bootstrap/templates/components/bs-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wva30beR",
    "block": "{\"symbols\":[\"option\",\"index\",\"id\",\"@optionLabelPath\",\"&default\",\"@value\",\"@name\",\"@required\",\"@disabled\",\"@autofocus\",\"@tabindex\",\"@form\",\"@title\",\"&attrs\",\"@options\"],\"statements\":[[4,\"each\",[[23,15,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"form-check\",[28,\"if\",[[23,0,[\"inline\"]],\" form-check-inline\"],null]]]],[8],[0,\"\\n\"],[4,\"let\",[[28,\"concat\",[[23,0,[\"elementId\"]],\"-\",[23,2,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"input\",false],[12,\"class\",\"form-check-input\"],[12,\"id\",[23,3,[]]],[12,\"checked\",[28,\"bs-eq\",[[23,1,[]],[23,6,[]]],null]],[12,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]],[23,1,[]]],null]],[12,\"name\",[23,7,[]]],[12,\"required\",[23,8,[]]],[12,\"disabled\",[23,9,[]]],[12,\"autofocus\",[23,10,[]]],[12,\"tabindex\",[23,11,[]]],[12,\"form\",[23,12,[]]],[12,\"title\",[23,13,[]]],[13,14],[12,\"type\",\"radio\"],[8],[9],[0,\"\\n      \"],[7,\"label\",true],[11,\"for\",[23,3,[]]],[10,\"class\",\"form-check-label\"],[8],[0,\"\\n\"],[4,\"if\",[[25,5]],null,{\"statements\":[[0,\"          \"],[14,5,[[23,1,[]],[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"get\",[[23,1,[]],[23,4,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/radio.hbs"
    }
  });

  _exports.default = _default;
});