define("ember-bootstrap/components/base/bs-nav", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-nav", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsNav, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Nav = (
  /**
    Component to generate [bootstrap navs](http://getbootstrap.com/components/#nav)
  
    ### Usage
  
    Use in combination with the yielded components
  
    * [Components.NavItem](Components.NavItem.html)
    * [`nav.dropdown`](Components.Dropdown.html)
  
    ```hbs
    <BsNav @type="pills" as |nav|>
      <nav.item>
        <nav.linkTo @route="foo">
          Foo
        </nav.linkTo>
      </nav.item>
      <nav.item>
        <nav.linkTo @route="bar" @model={{this.model}}>
          Bar
        </nav.linkTo>
      </nav.item>
    </BsNav>
    ```
  
    > Note: the use of angle brackets `<nav.linkTo>` as shown above is only supported for Ember >= 3.10, as it relies on
    > Ember's native implementation of the [`LinkComponent`](https://api.emberjs.com/ember/3.12/classes/Ember.Templates.helpers/methods/link-to?anchor=link-to).
    > For older Ember versions please use the legacy syntax with positional arguments: `{{#nav.link-to "bar" this.model}}Bar{{/nav.link-to}}`
  
    ### Nav styles
  
    The component supports the default bootstrap nav styling options "pills" and "tabs" through the `type`
    property, as well as the `justified`, `fill` and `stacked` properties.
  
    ### Active items
  
    Bootstrap 3 expects to have the `active` class on the `<li>` element that should be the active (highlighted)
    navigation item. To achieve that use the `@route` and optionally `@model` (or `@models`) and `@query` properties
    of the yielded `nav.linkTo` component just as you would for Ember's `<LinkTo>` component to create a link with proper
    `active` class support.
  
    ### Dropdowns
  
    Use the `nav.dropdown` contextual version of the [Components.Dropdown](Components.Dropdown.html) component
    with a `tagName` of "li" to integrate a dropdown into your nav:
  
    ```hbs
    <BsNav @type="pills" as |nav|>
      <nav.item>
        <nav.linkTo @route="index">
          Home
        </nav.linkTo>
      </nav.item>
      <nav.dropdown as |dd|>
        <dd.toggle>Dropdown <span class="caret"></span></dd.toggle>
        <dd.menu as |ddm|>
          <ddm.item><ddm.linkTo @route="foo">Foo</ddm.linkTo></ddm.item>
          <ddm.item><ddm.linkTo @route="bar">Bar</ddm.linkTo></ddm.item>
        </dd.menu>
      </nav.dropdown>
    </BsNav>
    ```
  
    ### Bootstrap 3/4 Notes
  
    Use [`nav.linkTo`](Components.NavLinkTo.html) for in-app links to ensure proper styling regardless of
    Bootstrap version. Explicit use of `<a>` tags in Bootstrap 4 must apply the `nav-link` class and manage
    the `active` state explicitly.
  
    The `fill` styling is only available with Bootstrap 4
  
    @class Nav
    @namespace Components
    @extends Ember.Component
    @public
  
   */
  _dec = (0, _component.layout)(_bsNav.default), _dec2 = (0, _component.tagName)('ul'), _dec3 = (0, _component.classNames)('nav'), _dec4 = (0, _component.classNameBindings)('typeClass', 'justified:nav-justified'), _dec5 = Ember.computed('type'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = (_temp = class Nav extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "type", _descriptor, this);

      _initializerDefineProperty(this, "justified", _descriptor2, this);

      _initializerDefineProperty(this, "stacked", _descriptor3, this);

      _initializerDefineProperty(this, "itemComponent", _descriptor4, this);

      _initializerDefineProperty(this, "linkToComponent", _descriptor5, this);

      _initializerDefineProperty(this, "dropdownComponent", _descriptor6, this);
    }

    get typeClass() {
      let type = this.get('type');
      return type ? "nav-".concat(type) : undefined;
    }
    /**
     * Special type of nav, either "pills" or "tabs"
     *
     * @property type
     * @type String
     * @default null
     * @public
     */


  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "typeClass"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "justified", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "stacked", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "itemComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-nav/item';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "linkToComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-nav/link-to';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "dropdownComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-dropdown';
    }
  })), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = Nav;
});