define("ember-bootstrap/components/base/bs-tooltip", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-contextual-help", "ember-bootstrap/templates/components/bs-tooltip", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsContextualHelp, _bsTooltip, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let Tooltip = (
  /**
    Component that implements Bootstrap [tooltips](http://getbootstrap.com/javascript/#tooltips).
  
    By default it will attach its listeners (mouseover and focus) to the parent DOM element to trigger
    the tooltip:
  
    ```hbs
    <button class="btn">
      <BsTooltip @title="This is a toolip" />
    </button>
    ```
  
    You can also use the component in a block form to set the title:
  
    ```hbs
    <button class="btn">
      <BsTooltip>
        This is a toolip
      </BsTooltip>
    </button>
    ```
  
    ### Trigger
  
    The trigger element is the DOM element that will cause the tooltip to be shown when one of the trigger events occur on
    that element. By default the trigger element is the parent DOM element of the component, and the trigger events will be
    "hover" and "focus".
  
    The `triggerElement` property accepts any CSS selector to attach the tooltip to any other existing DOM element.
    With the special value "parentView" you can attach the tooltip to the DOM element of the parent component:
  
    ```hbs
    <MyComponent>
      <BsTooltip @title="This is a toolip" @triggerElement="parentView"/>
    </MyComponent>
    ```
  
    To customize the events that will trigger the tooltip use the `triggerEvents` property, that accepts an array or a
    string of events, with "hover", "focus" and "click" being supported.
  
    ### Placement options
  
    By default the tooltip will show up on top of the trigger element. Use the `placement` property to change that
    ("top", "bottom", "left" and "right"). To make sure the tooltip will not exceed the viewport (see Advanced customization)
    you can set `autoPlacement` to true. A tooltip with `placement="right" will be placed to the right if there is enough
    space, otherwise to the left.
  
    ### Advanced customization
  
    Several other properties allow for some advanced customization:
    * `visible` to show/hide the tooltip programmatically
    * `fade` to disable the fade in transition
    * `delay` (or `delayShow` and `delayHide`) to add a delay
    * `viewportSelector` and `viewportPadding` to customize the viewport that affects `autoPlacement`
    * a `close` action is yielded, that allows you to close the tooltip:
  
    ```hbs
    <BsTooltip as |tt|>This is a toolip <button onclick={{action tt.close}}>Close</button></BsTooltip>
    ```
  
    See the individual API docs for each property.
  
    ### Actions
  
    When you want to react on the tooltip being shown or hidden, you can use one of the following supported actions:
    * `onShow`
    * `onShown`
    * `onHide`
    * `onHidden`
  
    @class Tooltip
    @namespace Components
    @extends Components.ContextualHelp
    @public
  */
  _dec = (0, _component.layout)(_bsTooltip.default), _dec2 = Ember.computed('overlayElement'), _dec(_class = (_class2 = (_temp = class Tooltip extends _bsContextualHelp.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "elementComponent", _descriptor, this);
    }

    /**
     * The DOM element of the arrow element.
     *
     * @property arrowElement
     * @type object
     * @readonly
     * @private
     */
    get arrowElement() {
      return this.get('overlayElement').querySelector('.tooltip-arrow');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "elementComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-tooltip/element';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "arrowElement", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "arrowElement"), _class2.prototype)), _class2)) || _class);
  _exports.default = Tooltip;
});