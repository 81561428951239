define("ember-bootstrap/components/bs-accordion", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-accordion"], function (_exports, _component, _bsAccordion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let Accordion = (_dec = (0, _component.classNames)('accordion'), _dec(_class = class Accordion extends _bsAccordion.default {}) || _class);
  _exports.default = Accordion;
});