define("ember-bootstrap/components/bs-modal/header/title", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-modal/header/title"], function (_exports, _component, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let ModalHeaderTitle = (_dec = (0, _component.tagName)('h5'), _dec(_class = class ModalHeaderTitle extends _title.default {}) || _class);
  _exports.default = ModalHeaderTitle;
});