define("ember-bootstrap/components/bs-form/element/help-text", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/help-text"], function (_exports, _component, _helpText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let FormElementHelpText = (_dec = (0, _component.classNames)('form-text'), _dec(_class = class FormElementHelpText extends _helpText.default {}) || _class);
  _exports.default = FormElementHelpText;
});