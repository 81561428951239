define("ember-bootstrap/components/base/bs-contextual-help/element", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-tooltip/element", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _element, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let ContextualHelpElement = (
  /**
   Internal (abstract) component for contextual help markup. Should not be used directly.
  
   @class ContextualHelpElement
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.layout)(_element.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed.reads('placement'), _dec4 = Ember.computed('popperClassNames.[]', 'class'), _dec5 = Ember.computed('arrowClass', 'autoPlacement', 'viewportElement', 'viewportPadding'), _dec(_class = _dec2(_class = (_class2 = (_temp = class ContextualHelpElement extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.ariaRole = 'tooltip';

      _initializerDefineProperty(this, "placement", _descriptor, this);

      _initializerDefineProperty(this, "actualPlacement", _descriptor2, this);

      _initializerDefineProperty(this, "fade", _descriptor3, this);

      _initializerDefineProperty(this, "showHelp", _descriptor4, this);

      _initializerDefineProperty(this, "renderInPlace", _descriptor5, this);

      _initializerDefineProperty(this, "popperTarget", _descriptor6, this);

      _initializerDefineProperty(this, "autoPlacement", _descriptor7, this);

      _initializerDefineProperty(this, "viewportElement", _descriptor8, this);

      _initializerDefineProperty(this, "viewportPadding", _descriptor9, this);

      _initializerDefineProperty(this, "arrowClass", _descriptor10, this);
    }

    /**
     * @property popperClassNames
     * @type {array}
     * @private
     */

    /**
     * @property popperClass
     * @type {string}
     * @private
     */
    get popperClass() {
      let classes = this.get('popperClassNames');
      let classProperty = this.get('class');

      if (typeof classProperty === 'string') {
        classes = classes.concat(classProperty.split(' '));
      }

      return classes.join(' ');
    }
    /**
     * popper.js modifier config
     *
     * @property popperModifiers
     * @type {object}
     * @private
     */


    get popperModifiers() {
      let self = this;
      return {
        arrow: {
          element: ".".concat(this.get('arrowClass'))
        },
        offset: {
          fn(data) {
            let tip = document.getElementById(self.get('id'));
            (true && !(tip) && Ember.assert('Contextual help element needs existing popper element', tip)); // manually read margins because getBoundingClientRect includes difference

            let marginTop = parseInt(window.getComputedStyle(tip).marginTop, 10);
            let marginLeft = parseInt(window.getComputedStyle(tip).marginLeft, 10); // we must check for NaN for ie 8/9

            if (isNaN(marginTop) || marginTop > 0) {
              marginTop = 0;
            }

            if (isNaN(marginLeft) || marginLeft > 0) {
              marginLeft = 0;
            }

            data.offsets.popper.top += marginTop;
            data.offsets.popper.left += marginLeft;
            return window.Popper.Defaults.modifiers.offset.fn.apply(this, arguments);
          }

        },
        preventOverflow: {
          enabled: this.get('autoPlacement'),
          boundariesElement: this.get('viewportElement'),
          padding: this.get('viewportPadding')
        },
        hide: {
          enabled: this.get('autoPlacement')
        },
        flip: {
          enabled: this.get('autoPlacement')
        }
      };
    }

    didReceiveAttrs() {
      (true && !(this.get('id')) && Ember.assert('Contextual help element needs id for popper element', this.get('id')));
    }

    updatePlacement(popperDataObject) {
      if (this.get('actualPlacement') === popperDataObject.placement) {
        return;
      }

      this.set('actualPlacement', popperDataObject.placement);
      Ember.run.scheduleOnce('afterRender', popperDataObject.instance, popperDataObject.instance.scheduleUpdate);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "placement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'top';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "actualPlacement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "showHelp", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "renderInPlace", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "popperTarget", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "autoPlacement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "viewportElement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "viewportPadding", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "arrowClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'arrow';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "popperClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "popperClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperModifiers", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "popperModifiers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updatePlacement", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "updatePlacement"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ContextualHelpElement;
});