define("ember-bootstrap/components/bs-form/element/errors", ["exports", "ember-bootstrap/components/base/bs-form/element/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormElementErrors extends _errors.default {
    constructor(...args) {
      super(...args);
      this.feedbackClass = 'invalid-feedback d-block';
    }

  }

  _exports.default = FormElementErrors;
});