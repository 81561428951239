define("ember-bootstrap/components/base/bs-nav/item", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-nav/item", "ember-bootstrap/mixins/component-parent", "ember-bootstrap/utils/cp/overrideable"], function (_exports, _component, _object, _item, _componentParent, _overrideable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let NavItem = (
  /**
  
   Component for each item within a [Components.Nav](Components.Nav.html) component. Have a look there for examples.
  
   @class NavItem
   @namespace Components
   @extends Ember.Component
   @uses Mixins.ComponentParent
   @public
   */
  _dec = (0, _component.layout)(_item.default), _dec2 = (0, _component.classNameBindings)('disabled', 'active'), _dec3 = (0, _component.tagName)('li'), _dec4 = (0, _overrideable.default)('_disabled', function () {
    return this.get('_disabled');
  }), _dec5 = (0, _overrideable.default)('_active', function () {
    return this.get('_active');
  }), _dec6 = Ember.computed.filter('children', function (view) {
    return view instanceof Ember.LinkComponent;
  }), _dec7 = Ember.computed.filterBy('childLinks', 'active'), _dec8 = Ember.computed.gt('activeChildLinks.length', 0), _dec9 = Ember.computed.filterBy('childLinks', 'disabled'), _dec10 = Ember.computed.gt('disabledChildLinks.length', 0), _dec11 = (0, _object.observes)('activeChildLinks.[]'), _dec12 = (0, _object.observes)('disabledChildLinks.[]'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = (_temp = class NavItem extends Ember.Component.extend(_componentParent.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "disabled", _descriptor, this);

      this._disabled = false;

      _initializerDefineProperty(this, "active", _descriptor2, this);

      this._active = false;

      _initializerDefineProperty(this, "childLinks", _descriptor3, this);

      _initializerDefineProperty(this, "activeChildLinks", _descriptor4, this);

      _initializerDefineProperty(this, "hasActiveChildLinks", _descriptor5, this);

      _initializerDefineProperty(this, "disabledChildLinks", _descriptor6, this);

      _initializerDefineProperty(this, "hasDisabledChildLinks", _descriptor7, this);
    }

    /**
     * Called when clicking the nav item
     *
     * @event onClick
     * @public
     */
    onClick() {}

    click() {
      this.onClick();
    }

    init() {
      super.init(...arguments);
      let {
        model,
        models
      } = this.getProperties('model', 'models');
      (true && !(!model || !models) && Ember.assert('You cannot pass both `@model` and `@models` to a nav item component!', !model || !models));
      this.get('activeChildLinks');
      this.get('disabledChildLinks');
    }

    _observeActive() {
      Ember.run.scheduleOnce('afterRender', this, this._updateActive);
    }

    _updateActive() {
      this.set('_active', this.get('hasActiveChildLinks'));
    }

    _observeDisabled() {
      Ember.run.scheduleOnce('afterRender', this, this._updateDisabled);
    }

    _updateDisabled() {
      this.set('_disabled', this.get('hasDisabledChildLinks'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "childLinks", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "activeChildLinks", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "hasActiveChildLinks", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "disabledChildLinks", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "hasDisabledChildLinks", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "_observeActive", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "_observeActive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_observeDisabled", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "_observeDisabled"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = NavItem;
});