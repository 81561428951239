define("ember-bootstrap/components/base/bs-tab/pane", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-tab/pane", "ember-bootstrap/mixins/component-child", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/cp/uses-transition", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _object, _pane, _componentChild, _transitionEnd, _usesTransition, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let TabPane = (
  /**
   The tab pane of a tab component.
   See [Components.Tab](Components.Tab.html) for examples.
  
   @class TabPane
   @namespace Components
   @extends Ember.Component
   @uses Mixins.ComponentChild
   @public
   */
  _dec = (0, _component.layout)(_pane.default), _dec2 = (0, _component.classNameBindings)('active', 'usesTransition:fade'), _dec3 = (0, _component.classNames)('tab-pane'), _dec4 = Ember.computed('activeId', 'elementId').readOnly(), _dec5 = (0, _usesTransition.default)('fade'), _dec6 = (0, _object.observes)('isActive'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = (_temp = class TabPane extends Ember.Component.extend(_componentChild.default) {
    constructor(...args) {
      super(...args);
      this.ariaRole = 'tabpanel';

      _initializerDefineProperty(this, "activeId", _descriptor, this);

      _initializerDefineProperty(this, "active", _descriptor2, this);

      _initializerDefineProperty(this, "showContent", _descriptor3, this);

      _initializerDefineProperty(this, "title", _descriptor4, this);

      _initializerDefineProperty(this, "groupTitle", _descriptor5, this);

      _initializerDefineProperty(this, "fade", _descriptor6, this);

      _initializerDefineProperty(this, "fadeDuration", _descriptor7, this);

      _initializerDefineProperty(this, "usesTransition", _descriptor8, this);
    }

    /**
     * True if this pane is active (visible)
     *
     * @property isActive
     * @type boolean
     * @readonly
     * @private
     */
    get isActive() {
      return this.get('activeId') === this.get('elementId');
    }
    /**
     * Used to apply Bootstrap's "active" class
     *
     * @property active
     * @type boolean
     * @default false
     * @private
     */


    /**
     * Show the pane
     *
     * @method show
     * @protected
     */
    show() {
      if (this.get('usesTransition')) {
        (0, _transitionEnd.default)(this.get('element'), this.get('fadeDuration')).then(() => {
          if (!this.get('isDestroyed')) {
            this.setProperties({
              active: true,
              showContent: true
            });
          }
        });
      } else {
        this.set('active', true);
      }
    }
    /**
     * Hide the pane
     *
     * @method hide
     * @protected
     */


    hide() {
      if (this.get('usesTransition')) {
        (0, _transitionEnd.default)(this.get('element'), this.get('fadeDuration')).then(() => {
          if (!this.get('isDestroyed')) {
            this.set('active', false);
          }
        });
        this.set('showContent', false);
      } else {
        this.set('active', false);
      }
    }

    _showHide() {
      if (this.get('isActive')) {
        this.show();
      } else {
        this.hide();
      }
    }

    init() {
      super.init(...arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        // isActive comes from parent component, so only available after render...
        this.set('active', this.get('isActive'));
        this.set('showContent', this.get('isActive') && this.get('fade'));
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "activeId", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "isActive", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isActive"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "active", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "showContent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "groupTitle", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "fadeDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "usesTransition", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "_showHide", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_showHide"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = TabPane;
});