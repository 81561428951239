define("ember-bootstrap/components/bs-form/element/layout/horizontal", ["exports", "ember-bootstrap/components/base/bs-form/element/layout/horizontal"], function (_exports, _horizontal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FormElementLayoutHorizontal = (_dec = Ember.computed('horizontalLabelGridClass'), (_class = class FormElementLayoutHorizontal extends _horizontal.default {
    /**
     * Computed property that specifies the Bootstrap offset grid class for form controls within a horizontal layout
     * form, that have no label.
     *
     * @property horizontalInputOffsetGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputOffsetGridClass() {
      if (Ember.isBlank(this.get('horizontalLabelGridClass'))) {
        return undefined;
      }

      let parts = this.get('horizontalLabelGridClass').split('-');
      parts.splice(0, 1, 'offset');
      return parts.join('-');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "horizontalInputOffsetGridClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputOffsetGridClass"), _class.prototype)), _class));
  _exports.default = FormElementLayoutHorizontal;
});