define("ember-bootstrap/components/bs-dropdown/toggle", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-dropdown/toggle"], function (_exports, _component, _toggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let Toggle = (_dec = (0, _component.classNameBindings)('inNav:nav-link'), _dec(_class = class Toggle extends _toggle.default {}) || _class);
  _exports.default = Toggle;
});