define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FE11v++m",
    "block": "{\"symbols\":[\"@inNav\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[28,\"component\",[[23,0,[\"buttonComponent\"]]],[[\"dropdown\",\"onClick\"],[[23,0,[]],[23,0,[\"toggleDropdown\"]]]]],[28,\"component\",[[23,0,[\"toggleComponent\"]]],[[\"dropdown\",\"inNav\",\"onClick\"],[[23,0,[]],[24,[\"inNav\"]],[23,0,[\"toggleDropdown\"]]]]],[28,\"component\",[[23,0,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[23,0,[\"isOpen\"]],[23,0,[\"direction\"]],[23,1,[]],[23,0,[\"toggleElement\"]],[23,0,[\"elementId\"]]]]],[23,0,[\"toggleDropdown\"]],[23,0,[\"openDropdown\"]],[23,0,[\"closeDropdown\"]],[23,0,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});