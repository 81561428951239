define("ember-bootstrap/components/base/bs-contextual-help", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/dom", "ember-bootstrap/utils/cp/uses-transition", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _object, _transitionEnd, _dom, _usesTransition, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class3, _class4, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _temp2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let InState = (_dec = Ember.computed.or('hover', 'focus', 'click'), (_class = (_temp = class InState extends Ember.Object {
    constructor(...args) {
      super(...args);
      this.hover = false;
      this.focus = false;
      this.click = false;

      _initializerDefineProperty(this, "showHelp", _descriptor, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showHelp", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));

  function noop() {}
  /**
    @class ContextualHelp
    @namespace Components
    @extends Ember.Component
    @private
  */


  let ContextualHelp = (_dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed.reads('visible'), _dec4 = Ember.computed.reads('delay'), _dec5 = Ember.computed.reads('delay'), _dec6 = Ember.computed.gt('delayShow', 0), _dec7 = Ember.computed.gt('delayHide', 0), _dec8 = Ember.computed('viewportSelector'), _dec9 = Ember.computed('triggerEvents'), _dec10 = Ember.computed('renderInPlace'), _dec11 = (0, _usesTransition.default)('fade'), _dec12 = (0, _object.observes)('visible'), _dec2(_class3 = (_class4 = (_temp2 = class ContextualHelp extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "placement", _descriptor2, this);

      _initializerDefineProperty(this, "autoPlacement", _descriptor3, this);

      _initializerDefineProperty(this, "visible", _descriptor4, this);

      _initializerDefineProperty(this, "inDom", _descriptor5, this);

      _initializerDefineProperty(this, "fade", _descriptor6, this);

      _initializerDefineProperty(this, "showHelp", _descriptor7, this);

      _initializerDefineProperty(this, "delay", _descriptor8, this);

      _initializerDefineProperty(this, "delayShow", _descriptor9, this);

      _initializerDefineProperty(this, "delayHide", _descriptor10, this);

      _initializerDefineProperty(this, "hasDelayShow", _descriptor11, this);

      _initializerDefineProperty(this, "hasDelayHide", _descriptor12, this);

      _initializerDefineProperty(this, "transitionDuration", _descriptor13, this);

      _initializerDefineProperty(this, "viewportSelector", _descriptor14, this);

      _initializerDefineProperty(this, "viewportPadding", _descriptor15, this);

      this._parentFinder = self.document ? self.document.createTextNode('') : '';

      _initializerDefineProperty(this, "triggerElement", _descriptor16, this);

      _initializerDefineProperty(this, "triggerEvents", _descriptor17, this);

      _initializerDefineProperty(this, "renderInPlace", _descriptor18, this);

      _initializerDefineProperty(this, "hoverState", _descriptor19, this);

      this.timer = null;

      _initializerDefineProperty(this, "usesTransition", _descriptor20, this);
    }

    /**
     * The id of the overlay element.
     *
     * @property overlayId
     * @type string
     * @readonly
     * @private
     */
    get overlayId() {
      return "overlay-".concat(Ember.guidFor(this));
    }
    /**
     * The DOM element of the arrow element.
     *
     * @property arrowElement
     * @type object
     * @readonly
     * @private
     */

    /**
     * The wormhole destinationElement
     *
     * @property destinationElement
     * @type object
     * @readonly
     * @private
     */


    get destinationElement() {
      return (0, _dom.getDestinationElement)(this);
    }
    /**
     * The DOM element of the viewport element.
     *
     * @property viewportElement
     * @type object
     * @readonly
     * @private
     */


    get viewportElement() {
      return document.querySelector(this.get('viewportSelector'));
    }
    /**
     * The DOM element that triggers the tooltip/popover. By default it is the parent element of this component.
     * You can set this to any CSS selector to have any other element trigger the tooltip/popover.
     * With the special value of "parentView" you can attach the tooltip/popover to the parent component's element.
     *
     * @property triggerElement
     * @type string
     * @public
     */


    /**
     * @method getTriggerTargetElement
     * @private
     */
    getTriggerTargetElement() {
      let triggerElement = this.get('triggerElement');

      if (!triggerElement) {
        return this._parent;
      } else if (triggerElement === 'parentView') {
        return this.get('parentView.element');
      } else {
        return document.querySelector(triggerElement);
      }
    }
    /**
     * The event(s) that should trigger the tooltip/popover - click | hover | focus.
     * You can set this to a single event or multiple events, given as an array or a string separated by spaces.
     *
     * @property triggerEvents
     * @type array|string
     * @default 'hover focus'
     * @public
     */


    get _triggerEvents() {
      let events = this.get('triggerEvents');

      if (!Ember.isArray(events)) {
        events = events.split(' ');
      }

      return events.map(event => {
        switch (event) {
          case 'hover':
            return ['mouseenter', 'mouseleave'];

          case 'focus':
            return ['focusin', 'focusout'];

          default:
            return event;
        }
      });
    }
    /**
     * If true component will render in place, rather than be wormholed.
     *
     * @property renderInPlace
     * @type boolean
     * @default false
     * @public
     */


    /**
     * @property _renderInPlace
     * @type boolean
     * @private
     */
    get _renderInPlace() {
      return this.get('renderInPlace') || !this.destinationElement;
    }
    /**
     * Current hover state, 'in', 'out' or null
     *
     * @property hoverState
     * @type string
     * @private
     */


    /**
     * Current state for events
     *
     * @property inState
     * @type {InState}
     * @private
     */
    get inState() {
      return InState.create();
    }
    /**
     * Ember.run timer
     *
     * @property timer
     * @private
     */


    /**
     * The DOM element of the overlay element.
     *
     * @property overlayElement
     * @type object
     * @readonly
     * @private
     */
    get overlayElement() {
      return document.getElementById(this.get('overlayId'));
    }
    /**
     * This action is called immediately when the tooltip/popover is about to be shown.
     *
     * @event onShow
     * @public
     */


    onShow() {}
    /**
     * This action will be called when the tooltip/popover has been made visible to the user (will wait for CSS transitions to complete).
     *
     * @event onShown
     * @public
     */


    onShown() {}
    /**
     * This action is called immediately when the tooltip/popover is about to be hidden.
     *
     * @event onHide
     * @public
     */


    onHide() {}
    /**
     * This action is called when the tooltip/popover has finished being hidden from the user (will wait for CSS transitions to complete).
     *
     * @event onHidden
     * @public
     */


    onHidden() {}
    /**
     * Called when a show event has been received
     *
     * @method enter
     * @param e
     * @private
     */


    enter(e) {
      if (e) {
        let eventType = e.type === 'focusin' ? 'focus' : 'hover';
        this.get('inState').set(eventType, true);
      }

      if (this.get('showHelp') || this.get('hoverState') === 'in') {
        this.set('hoverState', 'in');
        return;
      }

      Ember.run.cancel(this.timer);
      this.set('hoverState', 'in');

      if (!this.get('hasDelayShow')) {
        return this.show();
      }

      this.timer = Ember.run.later(this, function () {
        if (this.get('hoverState') === 'in') {
          this.show();
        }
      }, this.get('delayShow'));
    }
    /**
     * Called when a hide event has been received
     *
     * @method leave
     * @param e
     * @private
     */


    leave(e) {
      if (e) {
        let eventType = e.type === 'focusout' ? 'focus' : 'hover';
        this.get('inState').set(eventType, false);
      }

      if (this.get('inState.showHelp')) {
        return;
      }

      Ember.run.cancel(this.timer);
      this.set('hoverState', 'out');

      if (!this.get('hasDelayHide')) {
        return this.hide();
      }

      this.timer = Ember.run.later(this, function () {
        if (this.get('hoverState') === 'out') {
          this.hide();
        }
      }, this.get('delayHide'));
    }
    /**
     * Called for a click event
     *
     * @method toggle
     * @param e
     * @private
     */


    toggle(e) {
      if (e) {
        this.get('inState').toggleProperty('click');

        if (this.get('inState.showHelp')) {
          this.enter();
        } else {
          this.leave();
        }
      } else {
        if (this.get('showHelp')) {
          this.leave();
        } else {
          this.enter();
        }
      }
    }
    /**
     * Show the tooltip/popover
     *
     * @method show
     * @private
     */


    show() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (false === this.get('onShow')(this)) {
        return;
      } // this waits for the tooltip/popover element to be created. when animating a wormholed tooltip/popover we need to wait until
      // ember-wormhole has moved things in the DOM for the animation to be correct, so use Ember.run.next in this case


      let delayFn = !this.get('_renderInPlace') && this.get('fade') ? Ember.run.next : function (target, fn) {
        Ember.run.schedule('afterRender', target, fn);
      };
      this.set('inDom', true);
      delayFn(this, this._show);
    }

    _show(skipTransition = false) {
      this.set('showHelp', true); // If this is a touch-enabled device we add extra
      // empty mouseover listeners to the body's immediate children;
      // only needed because of broken event delegation on iOS
      // https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html
      // See https://github.com/twbs/bootstrap/pull/22481

      if ('ontouchstart' in document.documentElement) {
        let {
          children
        } = document.body;

        for (let i = 0; i < children.length; i++) {
          children[i].addEventListener('mouseover', noop);
        }
      }

      let tooltipShowComplete = () => {
        if (this.get('isDestroyed')) {
          return;
        }

        let prevHoverState = this.get('hoverState');
        this.get('onShown')(this);
        this.set('hoverState', null);

        if (prevHoverState === 'out') {
          this.leave();
        }
      };

      if (skipTransition === false && this.get('usesTransition')) {
        (0, _transitionEnd.default)(this.get('overlayElement'), this.get('transitionDuration')).then(tooltipShowComplete);
      } else {
        tooltipShowComplete();
      }
    }
    /**
     * Position the tooltip/popover's arrow
     *
     * @method replaceArrow
     * @param delta
     * @param dimension
     * @param isVertical
     * @private
     */


    replaceArrow(delta, dimension, isVertical) {
      let el = this.get('arrowElement');
      el.style[isVertical ? 'left' : 'top'] = "".concat(50 * (1 - delta / dimension), "%");
      el.style[isVertical ? 'top' : 'left'] = null;
    }
    /**
     * Hide the tooltip/popover
     *
     * @method hide
     * @private
     */


    hide() {
      if (this.get('isDestroyed')) {
        return;
      }

      if (false === this.get('onHide')(this)) {
        return;
      }

      let tooltipHideComplete = () => {
        if (this.get('isDestroyed')) {
          return;
        }

        if (this.get('hoverState') !== 'in') {
          this.set('inDom', false);
        }

        this.get('onHidden')(this);
      };

      this.set('showHelp', false); // if this is a touch-enabled device we remove the extra
      // empty mouseover listeners we added for iOS support

      if ('ontouchstart' in document.documentElement) {
        let {
          children
        } = document.body;

        for (let i = 0; i < children.length; i++) {
          children[i].removeEventListener('mouseover', noop);
        }
      }

      if (this.get('usesTransition')) {
        (0, _transitionEnd.default)(this.get('overlayElement'), this.get('transitionDuration')).then(tooltipHideComplete);
      } else {
        tooltipHideComplete();
      }

      this.set('hoverState', null);
    }
    /**
     * @method addListeners
     * @private
     */


    addListeners() {
      let target = this.get('triggerTargetElement');
      this.get('_triggerEvents').forEach(event => {
        if (Ember.isArray(event)) {
          let [inEvent, outEvent] = event;
          target.addEventListener(inEvent, this._handleEnter);
          target.addEventListener(outEvent, this._handleLeave);
        } else {
          target.addEventListener(event, this._handleToggle);
        }
      });
    }
    /**
     * @method removeListeners
     * @private
     */


    removeListeners() {
      try {
        let target = this.get('triggerTargetElement');
        this.get('_triggerEvents').forEach(event => {
          if (Ember.isArray(event)) {
            let [inEvent, outEvent] = event;
            target.removeEventListener(inEvent, this._handleEnter);
            target.removeEventListener(outEvent, this._handleLeave);
          } else {
            target.removeEventListener(event, this._handleToggle);
          }
        });
      } catch (e) {} // eslint-disable-line no-empty

    }
    /**
     * @method handleTriggerEvent
     * @private
     */


    handleTriggerEvent(handler, e) {
      let overlayElement = this.get('overlayElement');

      if (overlayElement && overlayElement.contains(e.target)) {
        return;
      }

      return handler.call(this, e);
    }

    _handleEnter(e) {
      this.handleTriggerEvent(this.enter, e);
    }

    _handleLeave(e) {
      this.handleTriggerEvent(this.leave, e);
    }

    _handleToggle(e) {
      this.handleTriggerEvent(this.toggle, e);
    }

    close() {
      // Make sure our click state is off, otherwise the next click would
      // close the already-closed tooltip/popover. We don't need to worry
      // about this for hover/focus because those aren't "stateful" toggle
      // events like click.
      this.set('inState.click', false);
      this.hide();
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this._parent = this._parentFinder.parentNode;
      this.triggerTargetElement = this.getTriggerTargetElement();
      this.addListeners();

      if (this.get('visible')) {
        Ember.run.next(this, this.show, true);
      }
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.removeListeners();
    }

    _watchVisible() {
      if (this.get('visible')) {
        this.show();
      } else {
        this.hide();
      }
    }

  }, _temp2), (_descriptor2 = _applyDecoratedDescriptor(_class4.prototype, "placement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'top';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class4.prototype, "autoPlacement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class4.prototype, "visible", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class4.prototype, "inDom", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.get('visible') && this.get('triggerTargetElement');
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class4.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class4.prototype, "showHelp", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class4.prototype, "delay", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class4.prototype, "delayShow", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class4.prototype, "delayHide", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class4.prototype, "hasDelayShow", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class4.prototype, "hasDelayHide", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class4.prototype, "transitionDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class4.prototype, "viewportSelector", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'body';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class4.prototype, "viewportPadding", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class4.prototype, "overlayId", [Ember.computed], Object.getOwnPropertyDescriptor(_class4.prototype, "overlayId"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "destinationElement", [Ember.computed], Object.getOwnPropertyDescriptor(_class4.prototype, "destinationElement"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "viewportElement", [_dec8], Object.getOwnPropertyDescriptor(_class4.prototype, "viewportElement"), _class4.prototype), _descriptor16 = _applyDecoratedDescriptor(_class4.prototype, "triggerElement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class4.prototype, "triggerEvents", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'hover focus';
    }
  }), _applyDecoratedDescriptor(_class4.prototype, "_triggerEvents", [_dec9], Object.getOwnPropertyDescriptor(_class4.prototype, "_triggerEvents"), _class4.prototype), _descriptor18 = _applyDecoratedDescriptor(_class4.prototype, "renderInPlace", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class4.prototype, "_renderInPlace", [_dec10], Object.getOwnPropertyDescriptor(_class4.prototype, "_renderInPlace"), _class4.prototype), _descriptor19 = _applyDecoratedDescriptor(_class4.prototype, "hoverState", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class4.prototype, "inState", [Ember.computed], Object.getOwnPropertyDescriptor(_class4.prototype, "inState"), _class4.prototype), _descriptor20 = _applyDecoratedDescriptor(_class4.prototype, "usesTransition", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class4.prototype, "_handleEnter", [Ember._action], Object.getOwnPropertyDescriptor(_class4.prototype, "_handleEnter"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "_handleLeave", [Ember._action], Object.getOwnPropertyDescriptor(_class4.prototype, "_handleLeave"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "_handleToggle", [Ember._action], Object.getOwnPropertyDescriptor(_class4.prototype, "_handleToggle"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "close", [Ember._action], Object.getOwnPropertyDescriptor(_class4.prototype, "close"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "_watchVisible", [_dec12], Object.getOwnPropertyDescriptor(_class4.prototype, "_watchVisible"), _class4.prototype)), _class4)) || _class3);
  _exports.default = ContextualHelp;
});