define("ember-bootstrap/components/base/bs-button-group", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-button-group", "ember-bootstrap/utils/cp/size-class", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsButtonGroup, _sizeClass, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let ButtonGroup = (
  /**
    Bootstrap-style button group, that visually groups buttons, and optionally adds radio/checkbox like behaviour.
    See http://getbootstrap.com/components/#btn-groups
  
    Use as a block level component with any number of [Components.Button](Components.Button.html) components provided as
    a yielded pre-configured contextual component:
  
    ```handlebars
    <BsButtonGroup as |bg|>
      <bg.button>1</bg.button>
      <bg.button>2</bg.button>
      <bg.button>3</bg.button>
    </BsButtonGroup>
    ```
  
    ### Radio-like behaviour
  
    Use the `type` property set to "radio" to make the child buttons toggle like radio buttons, i.e. only one button can be active.
    Set the `value` property of the buttons to something meaningful. The `value` property of the button group will then reflect
    the value of the active button:
  
    ```handlebars
    <BsButtonGroup @value={{this.buttonGroupValue}} @type="radio" @onChange={{action (mut this.buttonGroupValue}} as |bg|>
      <bg.button @type="default" @value={{1}}>1</bg.button>
      <bg.button @type="default" @value={{2}}>2</bg.button>
      <bg.button @type="default" @value={{3}}>3</bg.button>
    </BsButtonGroup>
  
    You selected: {{this.buttonGroupValue}}!
    ```
  
    ### Checkbox-like behaviour
  
    Set `type` to "checkbox" to make any number of child buttons selectable. The `value` property will be an array
    of all the values of the active buttons:
  
    ```handlebars
    <BsButtonGroup @value={{this.buttonGroupValue}} @type="checkbox" @onChange={{action (mut this.buttonGroupValue}} as |bg|>
      <bg.button @type="default" @value={{1}}>1</bg.button>
      <bg.button @type="default" @value={{2}}>2</bg.button>
      <bg.button @type="default" @value={{3}}>3</bg.button>
    </BsButtonGroup>
  
    You selected:
    <ul>
      {{#each value in this.buttonGroupValue}}
        <li>{{value}}</li>
      {{/each}}
    </ul>
    ```
  
    @class ButtonGroup
    @namespace Components
    @extends Ember.Component
    @public
  */
  _dec = (0, _component.layout)(_bsButtonGroup.default), _dec2 = (0, _component.classNameBindings)('vertical:btn-group-vertical:btn-group', 'sizeClass'), _dec3 = Ember.computed.equal('type', 'radio').readOnly(), _dec4 = (0, _sizeClass.default)('btn-group', 'size'), _dec(_class = _dec2(_class = (_class2 = (_temp = class ButtonGroup extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.ariaRole = 'group';

      _initializerDefineProperty(this, "buttonComponent", _descriptor, this);

      _initializerDefineProperty(this, "vertical", _descriptor2, this);

      _initializerDefineProperty(this, "isRadio", _descriptor3, this);

      _initializerDefineProperty(this, "size", _descriptor4, this);

      _initializerDefineProperty(this, "sizeClass", _descriptor5, this);
    }

    /**
     * This action is called whenever the button group's value should be changed because the user clicked a button.
     * You will receive the new value of the button group (based on the `type` property), which you should use to update the
     * `value` property.
     *
     * @event onChange
     * @param {*} value
     * @public
     */
    onChange() {}

    buttonPressed(pressedValue) {
      let newValue;

      if (this.get('isRadio')) {
        newValue = pressedValue;
      } else {
        if (!Ember.isArray(this.get('value'))) {
          newValue = Ember.A([pressedValue]);
        } else {
          newValue = Ember.A(this.get('value').slice());

          if (newValue.includes(pressedValue)) {
            newValue.removeObject(pressedValue);
          } else {
            newValue.pushObject(pressedValue);
          }
        }
      }

      this.get('onChange')(newValue);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "buttonComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-button-group/button';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "vertical", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isRadio", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "buttonPressed", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonPressed"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ButtonGroup;
});