define("ember-bootstrap/templates/components/bs-accordion/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aRLw/kQb",
    "block": "{\"symbols\":[\"@class\",\"&default\",\"@collapsed\"],\"statements\":[[4,\"component\",[[23,0,[\"collapseComponent\"]]],[[\"ariaRole\",\"collapsed\"],[\"tabpanel\",[23,3,[]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"card-body \",[23,1,[]]]]],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/body.hbs"
    }
  });

  _exports.default = _default;
});