define("ember-bootstrap/templates/components/bs-modal/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X/eg2hZd",
    "block": "{\"symbols\":[\"@centered\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog \",[23,0,[\"sizeClass\"]],\" \",[28,\"if\",[[23,1,[]],\"modal-dialog-centered\"],null]]]],[10,\"role\",\"document\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/dialog.hbs"
    }
  });

  _exports.default = _default;
});