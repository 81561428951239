define("ember-bootstrap/components/bs-carousel/slide", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-carousel/slide"], function (_exports, _component, _slide) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let CarouselSlide = (_dec = (0, _component.classNameBindings)('left:carousel-item-left', 'next:carousel-item-next', 'prev:carousel-item-prev', 'right:carousel-item-right'), _dec2 = (0, _component.classNames)('carousel-item'), _dec(_class = _dec2(_class = class CarouselSlide extends _slide.default {}) || _class) || _class);
  _exports.default = CarouselSlide;
});