define("ember-bootstrap/components/bs-accordion/item/title", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-accordion/item/title"], function (_exports, _component, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let AccordionItemTitle = (_dec = (0, _component.classNames)('card-header'), _dec(_class = class AccordionItemTitle extends _title.default {}) || _class);
  _exports.default = AccordionItemTitle;
});