define("ember-bootstrap/components/bs-form/element/label", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/label", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _label, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FormElementLabel = (_dec = (0, _component.tagName)('label'), _dec2 = (0, _component.classNameBindings)('invisibleLabel:sr-only', 'isHorizontalAndNotCheckbox:col-form-label', 'isCheckbox:form-check-label', 'labelClass', 'sizeClass'), _dec3 = (0, _component.attributeBindings)('formElementId:for'), _dec4 = Ember.computed('isHorizontal', 'isCheckbox'), _dec5 = Ember.computed('size', 'isHorizontal'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = (_temp = class FormElementLabel extends _label.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "size", _descriptor, this);
    }

    get isHorizontalAndNotCheckbox() {
      return this.get('isHorizontal') && !this.get('isCheckbox');
    }

    get sizeClass() {
      if (!this.get('isHorizontal')) {
        return undefined;
      }

      let size = this.get('size');
      return Ember.isBlank(size) ? null : "col-form-label-".concat(size);
    }
    /**
     * Property for size styling, set to 'lg', 'sm'
     *
     * @property size
     * @type String
     * @public
     */


  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "isHorizontalAndNotCheckbox", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isHorizontalAndNotCheckbox"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "sizeClass"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class2)) || _class) || _class) || _class);
  _exports.default = FormElementLabel;
});