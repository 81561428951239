define("ember-bootstrap/components/base/bs-collapse", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _object, _transitionEnd, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let Collapse = (
  /**
    An Ember component that mimics the behaviour of [Bootstrap's collapse.js plugin](http://getbootstrap.com/javascript/#collapse)
  
    ### Usage
  
    ```hbs
    <BsCollapse @collapsed={{this.collapsed}}>
      <div class="well">
        <h2>Collapse</h2>
        <p>This is collapsible content</p>
      </div>
    </BsCollapse>
    ```
  
    @class Collapse
    @namespace Components
    @extends Ember.Component
    @public
  */
  _dec = (0, _component.classNameBindings)('collapse', 'collapsing'), _dec2 = Ember.computed.not('transitioning'), _dec3 = Ember.computed.alias('transitioning'), _dec4 = Ember.computed.and('collapse', 'active'), _dec5 = (0, _object.observes)('collapsed'), _dec6 = (0, _object.observes)('collapsedSize'), _dec7 = (0, _object.observes)('expandedSize'), _dec(_class = (_class2 = (_temp = class Collapse extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "collapsed", _descriptor, this);

      _initializerDefineProperty(this, "active", _descriptor2, this);

      _initializerDefineProperty(this, "collapse", _descriptor3, this);

      _initializerDefineProperty(this, "collapsing", _descriptor4, this);

      _initializerDefineProperty(this, "showContent", _descriptor5, this);

      _initializerDefineProperty(this, "transitioning", _descriptor6, this);

      _initializerDefineProperty(this, "collapsedSize", _descriptor7, this);

      this.expandedSize = null;
      this.resetSizeWhenNotCollapsing = true;
      this.collapseDimension = 'height';
      this.transitionDuration = 350;
    }

    setCollapseSize(size) {
      let dimension = this.get('collapseDimension');
      (true && !(["width", "height"].indexOf(dimension) !== -1) && Ember.assert("collapseDimension must be either \"width\" or \"height\". ".concat(dimension, " given."), ["width", "height"].indexOf(dimension) !== -1));
      this.element.style.width = dimension === 'width' && size ? "".concat(size, "px") : '';
      this.element.style.height = dimension === 'height' && size ? "".concat(size, "px") : '';
    }
    /**
     * The action to be sent when the element is about to be hidden.
     *
     * @event onHide
     * @public
     */


    onHide() {}
    /**
     * The action to be sent after the element has been completely hidden (including the CSS transition).
     *
     * @event onHidden
     * @public
     */


    onHidden() {}
    /**
     * The action to be sent when the element is about to be shown.
     *
     * @event onShow
     * @public
     */


    onShow() {}
    /**
     * The action to be sent after the element has been completely shown (including the CSS transition).
     *
     * @event onShown
     * @public
     */


    onShown() {}
    /**
     * Triggers the show transition
     *
     * @method show
     * @protected
     */


    show() {
      this.get('onShow')();
      this.setProperties({
        transitioning: true,
        active: true
      });
      this.setCollapseSize(this.get('collapsedSize'));
      (0, _transitionEnd.default)(this.get('element'), this.get('transitionDuration')).then(() => {
        if (this.get('isDestroyed')) {
          return;
        }

        this.set('transitioning', false);

        if (this.get('resetSizeWhenNotCollapsing')) {
          this.setCollapseSize(null);
        }

        this.get('onShown')();
      });
      Ember.run.next(this, function () {
        if (!this.get('isDestroyed')) {
          this.setCollapseSize(this.getExpandedSize('show'));
        }
      });
    }
    /**
     * Get the size of the element when expanded
     *
     * @method getExpandedSize
     * @param action
     * @return {Number}
     * @private
     */


    getExpandedSize(action) {
      let expandedSize = this.get('expandedSize');

      if (Ember.isPresent(expandedSize)) {
        return expandedSize;
      }

      let collapseElement = this.get('element');
      let prefix = action === 'show' ? 'scroll' : 'offset';
      let measureProperty = Ember.String.camelize("".concat(prefix, "-").concat(this.get('collapseDimension')));
      return collapseElement[measureProperty];
    }
    /**
     * Triggers the hide transition
     *
     * @method hide
     * @protected
     */


    hide() {
      this.get('onHide')();
      this.setProperties({
        transitioning: true,
        active: false
      });
      this.setCollapseSize(this.getExpandedSize('hide'));
      (0, _transitionEnd.default)(this.get('element'), this.get('transitionDuration')).then(() => {
        if (this.get('isDestroyed')) {
          return;
        }

        this.set('transitioning', false);

        if (this.get('resetSizeWhenNotCollapsing')) {
          this.setCollapseSize(null);
        }

        this.get('onHidden')();
      });
      Ember.run.next(this, function () {
        if (!this.get('isDestroyed')) {
          this.setCollapseSize(this.get('collapsedSize'));
        }
      });
    }

    _onCollapsedChange() {
      let collapsed = this.get('collapsed');
      let active = this.get('active');

      if (collapsed !== active) {
        return;
      }

      if (collapsed === false) {
        this.show();
      } else {
        this.hide();
      }
    }

    _updateCollapsedSize() {
      if (!this.get('resetSizeWhenNotCollapsing') && this.get('collapsed') && !this.get('collapsing')) {
        this.setCollapseSize(this.get('collapsedSize'));
      }
    }

    _updateExpandedSize() {
      if (!this.get('resetSizeWhenNotCollapsing') && !this.get('collapsed') && !this.get('collapsing')) {
        this.setCollapseSize(this.get('expandedSize'));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "collapsed", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "active", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return !this.get('collapsed');
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "collapse", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "collapsing", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "showContent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "transitioning", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "collapsedSize", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "_onCollapsedChange", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCollapsedChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_updateCollapsedSize", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_updateCollapsedSize"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_updateExpandedSize", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "_updateExpandedSize"), _class2.prototype)), _class2)) || _class);
  _exports.default = Collapse;
});