define("ember-bootstrap/components/bs-dropdown/menu/divider", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-dropdown/menu/divider"], function (_exports, _component, _divider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let DropDownMenuDivider = (_dec = (0, _component.classNames)('dropdown-divider'), _dec(_class = class DropDownMenuDivider extends _divider.default {}) || _class);
  _exports.default = DropDownMenuDivider;
});